:root {
  --blue: #1f82c0;
  --magenta: #e20079;
  --lightBlue: #44aadb;
  --cyan: #66c3df;
  --plum: #ad448e;
  --lightPurple: #f7f6fa;
  --purple: #7f72ad;
  --navy: #334454;
  --black: #1d1d1b;
  --charcoal: #575755;
  --grey: #a9a9a9;
  --snow: #f5f5f6;
  --white: #ffffff;
  --red: #e56456;
  --lightYellow: #fef9ef;
  --yellow: #f0b549;
  --green: #67c057;
  --disabled-background: #f2f2f2;
  --disabled-color: #999;
  --disabled-border: #e6e6e6;
  --bronze: #923a07;
}
