.ggl-loader {
  position: relative;
  margin: 0 auto;
  width: 75px;
}
.ggl-loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.ggl-loader .circular {
  animation: gglrotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.ggl-loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ggldash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #0085cd;
}
@keyframes gglrotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ggldash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
