.youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.handle.react-draggable-dragging {
  animation-play-state: paused !important;
}

.swiper-container {
  overflow: visible !important;
}

.service-tool-carousel {
  width: 300px !important;
}

.most-read-articles-slider {
  width: 350px !important;
}

label.css-ncuz76-Checkbox {
  align-items: flex-start;
}

input[name="marketingAccepted"] {
  margin-top: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button {
  appearance: button;
  background-color: transparent;
  background-image: none;
}

@media screen and (max-width: 1200px) {
  body.menu-opened {
    overflow: hidden;
  }
}

.Inter-SemiBold {
  font-family: "Inter" !important;
  font-weight: 600;
}

.Inter-Regular {
  font-family: "Inter" !important;
  font-weight: 400;
}
